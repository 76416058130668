export const PARTNER_CONSTENT = {
  initialField: [
    {
      id: 1,
      label: 'Partner Name',
      fieldName: 'serviceName',
      fieldType: 'TEXT',
      dataType: 'STRING',
      placeholder: 'Partner Name',
      isValid: true,
      errorMessage: '',
      value: '',
      validations: { required: true, maxLength: 100, alphaNumericWithSpaceAndUnderscore: true },
    },
    {
      id: 2,
      label: 'Partner Alias',
      fieldName: 'serviceAlias',
      fieldType: 'TEXT',
      dataType: 'STRING',
      placeholder: 'Partner Alias',
      errorMessage: '',
      value: '',
      validations: {
        required: true,
        maxLength: 100,
        uppercaseAlphabaticWithUnderscore: true,
      },
      isValid: true,
    },
    {
      id: 3,
      label: 'Contract Start Date',
      fieldName: 'contractStartDate',
      fieldType: 'DATETIME',
      dataType: 'DATE',
      placeholder: 'Contract Start Date',
      isValid: true,
      errorMessage: '',
      value: null,
      dependencyIndex: 3,
      validations: { maxCompareDate: true },
    },
    {
      id: 4,
      label: 'Contract End Date',
      fieldName: 'contractEndDate',
      fieldType: 'DATETIME',
      dataType: 'DATE',
      placeholder: 'Contract End Date',
      isValid: true,
      errorMessage: '',
      value: null,
      dependencyIndex: 2,
      validations: { minCompareDate: true },
    },
    {
      id: 5,
      label: 'Email ID',
      fieldName: 'contactEmail',
      fieldType: 'TEXT',
      dataType: 'STRING',
      placeholder: 'Email ID',
      isValid: true,
      errorMessage: '',
      value: '',
      validations: { required: true },
    },
    {
      id: 6,
      label: 'Contact Number',
      fieldName: 'contactPhone',
      fieldType: 'NUMBER',
      dataType: 'NUMBER',
      placeholder: 'Contact Number',
      isValid: true,
      errorMessage: '',
      value: '',
      validations: {},
    },
    {
      id: 7,
      label: 'Status',
      fieldName: 'status',
      fieldType: 'SELECT',
      dataType: 'STRING',
      placeholder: 'Status',
      labelKey: 'value',
      isValid: true,
      isMultiple: false,
      errorMessage: '',
      value: { value: 'Active', key: 'ACTIVE' },
      options: [
        { value: 'Active', key: 'ACTIVE' },
        { value: 'In Active', key: 'INACTIVE' },
      ],
      validations: { required: true },
    },
    {
      id: 8,
      label: 'Allowed Masters',
      fieldName: 'allowedMasters',
      fieldType: 'SELECT',
      dataType: 'STRING',
      placeholder: 'Allowed Masters',
      labelKey: 'masterKey',
      valueKey: 'masterKey',
      isValid: true,
      isMultiple: true,
      errorMessage: '',
      value: [],
      options: [],
      formFieldsOptionsKey: 'masterList',
      validations: {},
    },
    {
      id: 9,
      label: 'Prefix Value',
      fieldName: 'partnerPrefix',
      fieldType: 'TEXT',
      dataType: 'STRING',
      placeholder: 'Prefix Value',
      isValid: true,
      errorMessage: '',
      value: '',
      validations: {},
    },
    {
      id: 10,
      label: 'Allowed Asset Type Configuration',
      fieldName: 'allowedAssetTypeConfigurations',
      fieldType: 'JSON1',
      addMore: true,
      dataType: 'JSON',
      errorMessage: '',
      validations: {},
      fieldConfig: [
        {
          label: 'Allowed Asset Type',
          fieldName: 'assetType',
          fieldType: 'SELECT',
          dataType: 'STRING',
          placeholder: 'Allowed Asset Type',
          labelKey: 'assetType',
          valueKey: 'assetType',
          isValid: true,
          isMultiple: false,
          errorMessage: '',
          value: '',
          options: [],
          formFieldsOptionsKey: 'assetTypeList',
          validations: {},
        },
        {
          label: 'Media Profile',
          fieldName: 'mediaProfile',
          fieldType: 'SELECT',
          dataType: 'STRING',
          placeholder: 'Media Profile',
          labelKey: 'value',
          isValid: true,
          isMultiple: false,
          errorMessage: '',
          value: '',
          options: [],
          formFieldsOptionsKey: 'mediaProfilesList',
          validations: {},
        },
        {
          label: 'Transcoding Process Type',
          fieldName: 'transcodingProcessType',
          fieldType: 'SELECT',
          dataType: 'STRING',
          placeholder: 'Transcoding Process Type',
          labelKey: 'key',
          isValid: true,
          isMultiple: false,
          errorMessage: '',
          value: '',
          options: [],
          formFieldsOptionsKey: 'transcodingProcessTypesList',
          validations: {},
        },
      ],
      fields: [],
    },
  ],
  AVOID_KEY_FOR_PHONE: ['E', 'e', '+', '-', '.'],
  errorMessageForAllowed: 'Please add Either Allowed Asset Type or Allowed Masters',
}
