import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import * as React from 'react'
import rearrangeIcon from '../../../Assets/Images/svgs/rearrangeIcon.svg'
import MUIDialog from '../../Shared/MUI-Dialog'

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { getRearrangeData } from '../../../Helpers/Util'
import { CommonTableHeaderModel } from '../../../Models/CommonTable'
import { MUIButton } from '../../Shared'
import './ReorderColumnDialog.scss'
interface dialogProps {
  open: boolean
  handleClose: () => void
  headers: CommonTableHeaderModel[]
  onSubmit: (data: CommonTableHeaderModel[]) => void
}
const ReorderColumnDialog: React.FC<dialogProps> = ({ open, handleClose, headers, onSubmit }) => {
  const [headersData, setHeadersData] = React.useState<CommonTableHeaderModel[]>([])
  const [imageHeader, setImageHeader] = React.useState<CommonTableHeaderModel>()
  React.useEffect(() => {
    const imageHeaderIndex = headers?.findIndex((item: any) => item.id === 'image')
    if (imageHeaderIndex >= 0) {
      setImageHeader(headers[imageHeaderIndex])
      // Create a new array without the image header by slicing
      const updatedHeaders = [
        ...headers.slice(0, imageHeaderIndex),
        ...headers.slice(imageHeaderIndex + 1),
      ]

      setHeadersData(updatedHeaders)
    } else {
      setHeadersData(headers)
    }
  }, [headers])
  const handleSuccess = () => {
    if (imageHeader) {
      const headersDataCopy = [...headersData]
      headersDataCopy.unshift(imageHeader)
      onSubmit(headersDataCopy)
    } else {
      onSubmit(headersData)
    }
    handleClose()
  }
  const onDragEndCategoryList = (ev: any) => {
    const copyData = getRearrangeData(ev, headersData)
    setHeadersData(copyData)
  }
  return (
    <MUIDialog open={open} handleClose={handleClose}>
      <div className='max-408'>
        <DialogTitle>
          <div className='circle__icon-wrapper primary'>
            <div className='circle__icon-inner primary'>
              <img src={rearrangeIcon} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className='' component={'div'} id='alert-dialog-slide-description'>
            <div className='col2 parag pt-1 paragraph-bold-3 '>Reorder Column Headers</div>
            <div className='col34 paragraph-regular-5  pr-1'>
              Drag and drop attributes to rearrange their order in listing as per your preference.
            </div>
            <DragDropContext onDragEnd={onDragEndCategoryList}>
              <Droppable droppableId='droppable'>
                {(provided) => (
                  <div
                    className='header__list-wrapper scrollable'
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {headersData.map((header, index) => (
                      <Draggable key={header.id} draggableId={header.id} index={index}>
                        {(provided) => (
                          <div
                            className='list-item mr-1'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className='d-flex align-items-center '>{header.label}</div>
                            <div className='ml-auto'>
                              <img src={rearrangeIcon} />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className='d-flex flex-wrap w-100 dialog-btns'>
            <div className='cancel-btn-reorder'>
              <MUIButton
                textTransform={'capitalize'}
                variant='outlined'
                size='large'
                color={'secondary'}
                type='button'
                label={'Cancel'}
                width={'100%'}
                handleClickCB={handleClose}
              />
            </div>
            <div className='accept-btn-reorder'>
              <MUIButton
                textTransform={'capitalize'}
                variant={'contained'}
                size='large'
                color={'primary'}
                type='button'
                handleClickCB={handleSuccess}
                label={'Submit'}
                width={'100%'}
              />
            </div>
          </div>
        </DialogActions>
      </div>
    </MUIDialog>
  )
}

export default ReorderColumnDialog
