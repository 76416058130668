import styled from '@emotion/styled'
import { Divider, Drawer } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS from '../../Constants'
import { ATTRIBUTE_REPOSITORY_CRUD, BULK_UPDATE } from '../../Constants/ApiDefinations'
import {
  getDisabledLabels,
  handleAttributesCreateJob,
  updateJobService,
} from '../../Helpers/BulkUpload'
import { combineErrorMessage } from '../../Helpers/Util'
import { TypeObject } from '../../Models'
import mainApiService from '../../Services'
import { useAppDispatch } from '../../Store/Hooks'
import { showAlert } from '../../Store/Slice/alert.slice'
import styles from '../AssetTypeDefinition/Editor/AssetTypeDefinitionEditor.module.scss'
import { MUIAutocompleteMultiSelect, MUIButton, MUIFieldWithoutControl } from '../Shared'

const CustomCSSDrawer = styled(Drawer)(() => ({
  zIndex: '1201 !important',
}))

interface JobDrawerModal {
  open: boolean
  handleClose: (value: boolean) => void
  pageType: string | undefined
  jobInfo: TypeObject
  id?: string | null
}

const JobDrawerEditor: React.FC<JobDrawerModal> = ({
  open,
  handleClose,
  pageType,
  jobInfo,
  id = null,
}: JobDrawerModal) => {
  const [bulkUpdateAttributeList, setBulkUpdateAttributeList] = useState<TypeObject[]>([])
  const [disabledLabels, setDisabledLabel] = useState<string[]>([])
  const [jobData, setJobData] = useState<{ jobName: string; selectedAttributes: TypeObject[] }>({
    jobName: '',
    selectedAttributes: [],
  })

  const [attributesData, setAttributesData] = useState<TypeObject[]>([])

  const [existingJobData, setExistingJobData] = useState<{
    jobName: string
    selectedAttributes: TypeObject[]
  }>({
    jobName: '',
    selectedAttributes: [],
  })

  const [errors, setErrors] = useState<any>({})
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    getAttributeList()
  }, [])

  useEffect(() => {
    setErrors({})
    if (!open) {
      setJobData({ jobName: '', selectedAttributes: [] })
      setDisabledLabel([])
    }
  }, [open])

  useEffect(() => {
    const removedExternalAttributes = bulkUpdateAttributeList?.filter(
      (attr) => !CONSTANTS.BULK_UPDATE_DISABLED_EXTRAS.includes(attr.fieldName as string),
    )
    const selectedAttributeLabels = jobData.selectedAttributes.map((attr) => attr.label as string)
    const labelsData = getDisabledLabels(bulkUpdateAttributeList ?? [], selectedAttributeLabels)
    setDisabledLabel([...labelsData])

    setAttributesData([
      ...(removedExternalAttributes || []),
      ...CONSTANTS.BULK_UPDATE_ADDITIONAL_FIELD,
    ])
  }, [jobData.selectedAttributes, bulkUpdateAttributeList])

  useEffect(() => {
    if (bulkUpdateAttributeList?.length) {
      if (Object.keys(jobInfo)?.length && id) {
        const data = { jobName: jobInfo?.jobName, selectedAttributes: jobInfo?.attributes ?? [] }
        setJobData({ ...data })
        setExistingJobData({ ...data })
      }
    }
  }, [jobInfo, bulkUpdateAttributeList])

  useEffect(() => {
    if (!open) {
      setJobData({ jobName: '', selectedAttributes: [] })
      setErrors({})
    }
  }, [open])

  const getAttributeList = async () => {
    const requestData = ATTRIBUTE_REPOSITORY_CRUD.LIST_ATTRIBUTE_REPO(
      `isActive=1&limit=${CONSTANTS.API_DEFAULT_LENGTH}&isBulkUpdate=true`,
    )
    const result: any = await mainApiService(requestData)
    if (result?.data?.records) {
      setBulkUpdateAttributeList(result.data.records)
    }
  }

  const handleAttributes = (ev: any, newValues: TypeObject[], reason: string) => {
    const data: { disabledLabel: string[]; selectedAttributes: TypeObject[] } | undefined =
      handleAttributesCreateJob(
        newValues,
        reason,
        bulkUpdateAttributeList,
        jobData.selectedAttributes,
      )

    if (data) {
      setDisabledLabel(data.disabledLabel)
      setJobData((prev) => ({ ...prev, selectedAttributes: data.selectedAttributes }))
    }
  }

  const handleChange = (value: string) => {
    setJobData((prev) => ({ ...prev, jobName: value }))
  }

  const handleErrors = () => {
    let errorData = {}
    if (!jobData.jobName) {
      errorData = {
        ...errorData,
        jobName: { message: CONSTANTS.MESSAGES.JOB_NAME_REQUIRED },
      }
    }
    if (jobData.selectedAttributes.length === 0) {
      errorData = {
        ...errorData,
        selectedAttribute: { message: CONSTANTS.MESSAGES.ATTRIBUTES_REQUIRED },
      }
    }
    setErrors(errorData)
    return errorData
  }

  const createJob = async () => {
    const requestedData = BULK_UPDATE.ADD_JOB({
      jobName: jobData.jobName?.trim(),
      attributes: jobData.selectedAttributes,
    })

    const result: any = await mainApiService(requestedData)
    if (result?.error) {
      if (result?.error?.blitzCode === CONSTANTS.BLITZ_JOB_EXIST_ERROR_CODE) {
        setErrors((prev: any) => ({
          ...prev,
          jobName: { message: result?.error?.message },
        }))
      } else {
        dispatch(showAlert([true, combineErrorMessage(result), 'error']))
      }
      return
    }

    handleClose(false)
    navigate('/bulk-update/edit/' + result?.data?.jobId)
  }

  const updateJob = async () => {
    const requestedData: TypeObject = {
      jobId: id,
      attributes: jobData.selectedAttributes,
    }

    if (existingJobData.jobName !== jobData.jobName) requestedData.jobName = jobData.jobName?.trim()

    const result = await updateJobService(requestedData, false, false)
    if (result) {
      if (result?.error) {
        if (result?.error?.blitzCode === CONSTANTS.BLITZ_JOB_EXIST_ERROR_CODE) {
          setErrors((prev: any) => ({
            ...prev,
            jobName: { message: result?.error?.message },
          }))
        } else {
          dispatch(showAlert([true, combineErrorMessage(result), 'error']))
        }
        return
      } else {
        handleClose(false)
      }
    }
  }

  const onSubmit = async (ev: any) => {
    ev?.preventDefault()
    setErrors({})
    if (Object.keys(handleErrors())?.length) {
      return
    }

    if (pageType === CONSTANTS.PAGE_TYPE.EDIT) {
      updateJob()
    } else {
      createJob()
    }
  }

  return (
    <CustomCSSDrawer
      anchor='right'
      open={open}
      PaperProps={{
        sx: {
          width: '428px',
        },
      }}
    >
      <div className={styles.header}>
        <p className={`heading-5 ${styles.sectionTitle}`}>Create Update</p>
        <Divider
          sx={{
            margin: '.5rem 1.5rem 0.5rem 1.5rem',
            borderColor: '#F3F2F1',
            width: 'calc(100% - 3rem)',
          }}
        />
      </div>
      <div className={styles.validationCompWrapper + ' ' + styles.bulkUpdate}>
        <div className={`${styles.sectionTitle} input mt-1`}>
          <p className={'heading-6 mb-1'}>Job Name</p>
          <MUIFieldWithoutControl
            name='jobName'
            type='text'
            error={errors}
            value={jobData.jobName}
            onChange={handleChange}
            label='Job Name'
          />
        </div>
        <div className={`${styles.sectionTitle} input  mt-1`}>
          <p className={'heading-6 mb-1'}>Attribute</p>
          <MUIAutocompleteMultiSelect
            usingFor={'assetTypeMetaCategory'}
            width='100%'
            label={'Attribute'}
            options={attributesData}
            error={errors}
            labelKey='label'
            name='selectedAttribute'
            value={jobData.selectedAttributes}
            onInputChange={handleAttributes}
            limitTags={-1}
            highlighSelectedField={false}
            disabledOptions={disabledLabels}
            disabledOptionsByLable='label'
            selectAll={true}
          />
        </div>
      </div>
      <div className={`d-flex ${styles['action__padding']} mt-5`}>
        <div className='w-50'>
          <MUIButton
            className={styles.btnWrapper}
            variant={'outlined'}
            height='48px'
            width={'100%'}
            color={'secondary'}
            type='button'
            label={'Cancel'}
            handleClickCB={() => handleClose(false)}
            borderRadius='8px'
          />
        </div>
        <div className='w-50'>
          <MUIButton
            className={styles.btnWrapper}
            variant={'contained'}
            height='48px'
            width={'100%'}
            color={'primary'}
            disabled={!(jobData?.jobName && jobData?.selectedAttributes?.length)}
            type='submit'
            label={pageType === CONSTANTS.PAGE_TYPE.EDIT ? 'Update' : 'Create'}
            handleClickCB={onSubmit}
            borderRadius='8px'
          />
        </div>
      </div>
    </CustomCSSDrawer>
  )
}

export default JobDrawerEditor
